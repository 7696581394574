export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      badge: {
        variant: 'primary'
      }
    },
    {
      title: true,
      name: 'Indicadores',
      class: '',
      wrapper: {
        element: '',
        attributes: {}
      }
    },
    {
      name: 'Producción',
      url: '/indicadores/produccion',
      icon: 'icon-chart'
    },
    {
      name: 'Legal',
      url: '/indicadores/legal',
      icon: 'icon-chart'
    },
    {
      name: 'Biodiversidad',
      url: '/indicadores/biodiversidad',
      icon: 'icon-chart'
    },
    {
      name: 'Agua',
      url: '/indicadores/agua',
      icon: 'icon-chart'
    },
    {
      name: 'Gestión de Residuos',
      url: '/indicadores/residuos',
      icon: 'icon-chart'
    },
    {
      name: 'Emisiones GEI',
      url: '/indicadores/emisiones',
      icon: 'icon-chart'
    },
    {
      name: 'Empleo',
      url: '/indicadores/empleo',
      icon: 'icon-chart'
    },
    {
      name: 'Proveedores',
      url: '/indicadores/proveedores',
      icon: 'icon-chart'
    },
    {
      name: 'Comunidad',
      url: '/indicadores/comunidad',
      icon: 'icon-chart'
    }
  ]
}
